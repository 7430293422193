import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { AioAboutComponent } from './aio-about/aio-about.component';
import { InnerHeaderComponent } from './header/inner-header.component';
import { HowToHostActComponent } from './how-to-host-act/how-to-host-act.component';
import { AioStoriesComponent } from './aio-stories/aio-stories.component';
import { EkGaramChaiComponent } from './ek-garam-chai/ek-garam-chai.component';
import { TheCorporateResumeComponent } from './the-corporate-resume/the-corporate-resume.component';
import { FaqComponent } from './faq/faq.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TrustSefetyComponent } from './trust-sefety/trust-sefety.component';
import { AboutCookiesComponent } from './about-cookies/about-cookies.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { ContactComponent } from './contact/contact.component';
import { WhyAioComponent } from './why-aio/why-aio.component';
import {Routes, RouterModule} from '@angular/router';
import { HomeService } from './home/services/home-service';
import { FooterService } from './footer/services/footer-service';
import { TestimonialService } from './testimonials/services/testimonial-service';
import { AioStoriesServices } from './aio-stories/services/aio-stories-services'
import { HttpClientModule } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContactService } from './contact/services/contact.service';
import { AioNftComponent } from './aio-nft/aio-nft.component';

const routes: Routes = [
    { path: 'home', redirectTo: ''},
    { path: 'about', component: AioAboutComponent, pathMatch: 'full' },
    { path: 'aio-stories', component: AioStoriesComponent, pathMatch: 'full' },
    { path: 'testimonial/:id', component: TestimonialsComponent, pathMatch: 'full' },
    { path: 'how-to-host-act', component: HowToHostActComponent, pathMatch: 'full' },
    { path: 'about-cookies', component: AboutCookiesComponent, pathMatch: 'full' },
    { path: 'why-aio', component: WhyAioComponent, pathMatch: 'full'},
    { path: 'faq', component: FaqComponent, pathMatch: 'full' },
    { path: 'contact', component: ContactComponent, pathMatch: 'full' },
    { path: 'privacy-policy', component: PrivacyPolicyComponent, pathMatch: 'full' },
    { path: 'terms', component: TermsComponent, pathMatch: 'full'},
    { path: 'trust', component: TrustSefetyComponent, pathMatch: 'full'},
    { path: 'ek-garam-chai', component: EkGaramChaiComponent, pathMatch: 'full'},
    { path: 'the-corporate-resume', component: TheCorporateResumeComponent, pathMatch: 'full'},
    { path: 'about-cookies', component: AboutCookiesComponent, pathMatch: 'full'},
    { path: 'aio-nft', component: AioNftComponent, pathMatch: 'full'},
    { path: '', component: HomeComponent, pathMatch: 'full'}
];
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AioAboutComponent,
    InnerHeaderComponent,
    HowToHostActComponent,
    AioStoriesComponent,
    EkGaramChaiComponent,
    TheCorporateResumeComponent,
    FaqComponent,
    TermsComponent,
    PrivacyPolicyComponent,
    TrustSefetyComponent,
    AboutCookiesComponent,
    TestimonialsComponent,
    ContactComponent,
    WhyAioComponent,
    AboutCookiesComponent,
    AioNftComponent
    ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes, { useHash: false})
  ],
  providers: [HomeService, TestimonialService, FooterService, ContactService,AioStoriesServices,{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
