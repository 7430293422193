import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-aio-about',
  templateUrl: './aio-about.component.html',
  styleUrls: ['./aio-about.component.css']
})
export class AioAboutComponent implements OnInit {
 
  constructor(private location: Location) { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }
  load(path):void {
    location.href = `${location.href.split('#/')[0]}#/${path}`
    location.reload()
  }
  
}
