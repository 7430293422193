import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TestimonialService } from './services/testimonial-service'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Location } from '@angular/common';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.css']
})
export class TestimonialsComponent implements OnInit {
  iframeSrc: SafeUrl;
  public testimonialList:any;
  public currentTestimonial:any;
  public routeParam:any;

  constructor(private sanitizer: DomSanitizer, public route: ActivatedRoute, public testimonialService:TestimonialService, private location: Location) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.route.params.subscribe(params => {
      this.routeParam = params['id']
      this.getTestimonial(params['id'])
      this.getTestimonialList();
    })
  }

  getTestimonial(id){
    var testimonialArray = this.testimonialService.getTestimonial();
    var selected = testimonialArray.findIndex(item => item.id == id);
    this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(testimonialArray[selected].embedUrl)
    testimonialArray[selected].embedUrl = this.iframeSrc
    this.currentTestimonial = testimonialArray[selected]; 
  }

  getTestimonialList(){
    this.testimonialList = this.testimonialService.getTestimonial();
  }

  load(path):void {
    location.href = `${location.href.split('#/')[0]}#/${path}`
    location.reload()
  }

}
