import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()

export class TestimonialService {

    public testimonialJson: any = [{
        id: "001",
        name: "Gurpreet",
        location: "Punjab",
        designation: "Actor",
        embedUrl: "https://www.youtube.com/embed/-AZSLoYTIig",
        video: "https://www.youtube.com/watch?v=-AZSLoYTIig",
        text: "Gurpreet is a known theatre artist from Punjab. Always aspired to do more than theatre and plays, it was difficult for him to leave his hometown to just go and give AUDITIONS. He then joined AIO APP and explored a new and easy way of giving GENUINE AUDITIONS, sitting at home, just through his Phone :) Check out his journey and review of ACT IT OUT app."
    }, {
        id: "002",
        name: "Saudamani",
        location: "Dehradun",
        designation: "Actor",
        embedUrl: "https://www.youtube.com/embed/MyF_KrKZdi4",
        video: "https://www.youtube.com/watch?v=MyF_KrKZdi4",
        text: "Saudamini is an experienced Actor and is now using AIO app to give auditions with ease and at her convenience."
    }, {
        id: "003",
        name: "Preeta",
        location: "Mumbai",
        designation: "Actor",
        embedUrl: "https://www.youtube.com/embed/pwII-0XWezY",
        video: "https://www.youtube.com/watch?v=pwII-0XWezY",
        text: `Preeta - A talented actress, found "Act It Out" App useful for her career. Here's what she has to say about her experience with AIO App and successfully landing a role through it.`
    }, {
        id: "004",
        name: "Pavneet",
        location: "Ahmedabad",
        designation: "Actor",
        embedUrl: "https://www.youtube.com/embed/K2nIPLog5K0",
        video: "https://www.youtube.com/watch?v=K2nIPLog5K0",
        text: "Here’s Pavneet talking about his experience with AIO App! Pavneet got selected for Tata Motors Short Film by giving audition through AIO App"
    }, {
        id: "005",
        name: "Rohit",
        location: "Mumbai",
        designation: "Actor",
        embedUrl: "https://www.youtube.com/embed/l31-UwtyurI",
        video: "https://www.youtube.com/watch?v=l31-UwtyurI",
        text: "Here's Rohit, sharing his experience about ACT IT OUT app! Rohit is a registered AIO User, he gave a few Auditions through AIO app and landed a LEAD ROLE in a Digital Ad with a renowned Shoe Brand!"
    }, {
        id: "006",
        name: "Vipul",
        location: "Mumbai",
        designation: "Actor",
        embedUrl: "https://www.youtube.com/embed/ofAMEyAkKwQ",
        video: "https://www.youtube.com/watch?v=ofAMEyAkKwQ",
        text: "Vipul created his account on AIO app, gave a few auditions for different requirements and got selected for Mochi Shoes Digital Ad!"
    }, {
        id: "007",
        name: "Solanki",
        location: "Mumbai",
        designation: "Actor",
        embedUrl: "https://www.youtube.com/embed/Nm_LuodOWto",
        video: "https://www.youtube.com/watch?v=Nm_LuodOWto",
        text: "Little effort can take you to new heights :) Here’s Solanki Sharma - Sharing her experience and success through ACT IT OUT App!"
    }, {
        id: "008",
        name: "Mikhil Musale",
        location: "Ahmedabad",
        designation: "Director",
        image: "https://apis.actitout.in/Mikhil Musale.jpg",
        text: "We really loved the concept and decided to collaborate with AIO. Acting as a career option has garnered a lot of acceptance over the years in India. If this app becomes successful then it can play a major part for this industry."
    }, {
        id: "009",
        name: "Mohd. Nazeem",
        location: "Mumbai",
        designation: "Co-Founder, Take One Casting",
        image: "https://apis.actitout.in/Mohd. Nazeem.jpg",
        text: "AIO is a talented team with a vision to upgrade the casting industry into a user-friendly platform for new comers."
    }, {
        id: "0010",
        name: "Ritwika",
        location: "Mumbai",
        designation: "Actor",
        embedUrl: "https://www.youtube.com/embed/xzKyOBaTnlw",
        video: "https://www.youtube.com/watch?v=xzKyOBaTnlw",
        text: "Ritwika - a talented Actress and Anchor found success through ACT IT OUT Audition App! Check out this video to find out what Ritwika has to say about her experience with AIO App!"
    },{
        id: "0011",
        name: "Jayesh Kumar",
        location: "Mumbai",
        designation: "Co-Founder, GirlsXP",
        image: "https://apis.actitout.in/Jayesh Kumar.jpg",
        text: "AIO has helped us in getting the finest raw talent across local region saving us lot of time. The team at AIO is proactive and takes care of every minute requirement. The overall team effort helped us in getting the video out on time and sharing it across. The whole credit of video going viral on social platform goes to team AIO.  Would love to work with AIO in future projects."
    }, {
        id: "0012",
        name: "Ojaswwee Sharma",
        location: "Chandigarh",
        designation: "Director, Pinaka MediaWorks",
        image: "https://apis.actitout.in/Ojaswwee Sharma.jpg",
        text: "Act It Out has made it easier for people of all age groups to actually scroll through auditions, pan India, and apply for them from virtually anywhere, and with no struggle to travel hundreds or maybe thousands of kilometers to audition for film or videos, which actually benefits the applicant with opportunities and reduces exploitation. I recommend this platform after having successfully casted using this platform for my film projects! Try out “Act It Out”. Wishing good luck!"
    }, {
        id: "0013",
        name: "Jagdeep",
        location: "New Delhi",
        designation: "Actor",
        embedUrl: "https://www.youtube.com/embed/uQ-RGGkhDH0",
        video: "https://www.youtube.com/watch?v=uQ-RGGkhDH0",
        text: "Follow the story of Jagdeep who has chosen to give Auditions from his home town in Punjab through ACT IT OUT app!"
    },{
        id: "0014",
        name: "Amit Sharma",
        location: "Mumbai",
        designation: "Founder at Amit Sharma Casting",
        image: "https://apis.actitout.in/Amit Sharma.jpg",
        text: "It was a great work experience casting talents from Act it out. It is indeed a fantastic platform for both artists and casting directors. Best part is that the wether you have a small character requirement or complete casting requirement, Act it out always is very supportive and promote talents in right way. I hope to continue my amazing collaboration with Act it out and I am sure many talented artists will get casted by us."
    }, {
        id: "0015",
        name: "Anand Deo Deshmukh",
        location: "Mumbai",
        designation: "Ad Factory Films- Founder",
        image: "https://apis.actitout.in/Anand Deo Deshmukh.jpg",
        text: `Act It Out is a digital platform which is really beneficial for the actors. An actor can give auditions from his place & get connected with the Production House for the final round of audition in a systematic & short span of time. I have registered on Act It Out as a Production House & is really easy to use this platform to find the right talent for the projects. 
        I would recommend Act It Out to all the budding artists & Production Houses.`
    }, {
        id: "0016",
        name: "Harshmeet",
        location: "Patiala",
        designation: "Actor",
        embedUrl: "https://www.youtube.com/embed/P1L5TDalYms",
        video: "https://www.youtube.com/watch?v=P1L5TDalYms",
        text: "“Mujhe na.. bachpan se hi acting ka baut shaunk tha”. Giving Auditions is a struggle for kids as well! Missing school days, connecting with genuine Casting directors & giving genuine Auditions are some of the few challenges that Child Actors and their parents face! Now with ACT IT OUT, Parents can upload their child’s Audition video anytime from anywhere for Genuine Auditions! Check out what our star kids, Harsh & Mehak, have to share about their experience with AIO."
    },{
        id: "0017",
        name: "Tanya",
        location: "Gurgaon",
        designation: "Actor",
        image: "https://apis.actitout.in/Tanya.png",
        text: "AIO App has made it very easy to give Auditions. I am able to see proper Audition details of each requirement before i apply for it. The requirements are genuine and sitting at home i get the chance to get connected to Production Houses and showcase my talent to them."
    }, {
        id: "0018",
        name: "Guneet",
        location: "Mumbai",
        designation: "Actor",
        embedUrl: "https://www.youtube.com/embed/dnsl8U793pc",
        video: "https://www.youtube.com/watch?v=dnsl8U793pc",
        text: "Guneet landed a role in a viral video through AIO app which got over 2 million views"
    },{
        id: "0019",
        name: "Amanpreet Singh Ammy",
        location: "Mumbai",
        designation: "Casting Director",
        image: "https://apis.actitout.in/Amanpreet Singh Ammy.jpg",
        text: `It’s great to see a platform like ACT IT OUT which has made a big change in the process of casting.
        It is a very time saving process for production houses and casting directors as we can see self made introductions and monologues by Actors from all over India and connect with them in just a few clicks.`
    }];
    constructor(public http: HttpClient) { }

    errorHandler(error: Response) {
        return Observable.throw(error.json() || "Server error");
    }

    getTestimonial(){
        return this.testimonialJson;
    }
}