import { Component, OnInit } from '@angular/core';
import { HomeService } from '../home/services/home-service';
import { Location } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-inner-header',
  templateUrl: './inner-header.component.html',
  styleUrls: ['./inner-header.component.css']
})
export class InnerHeaderComponent implements OnInit {
  public mainTagLine:any;
  public showInnerHeader = true;
  constructor(public homeService:HomeService,private router:Router, private location: Location) { }

  ngOnInit() {
    this.getTagLine()
  }

  getTagLine(){
    
    this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe((event: any) => {
      if(event.url == "/aio-nft"){
        this.showInnerHeader = false;
      }
    });
    this.homeService.getCountry().subscribe((response:any)=>{
      console.log(response)
      
          if(response.country == 'IN'){
            this.mainTagLine = {
              firstPart: 'Ghar Baithe',
              secondPart: 'Auditions!'
            }
          }
          else{
            this.mainTagLine = {
              firstPart: 'Give Your Best',
              secondPart: 'Shot!'
            }
          }
    })
  }

  load(path):void {
    location.href = `${location.href.split('#/')[0]}#/${path}`
    location.reload()
  }

}
