export class Constants {
    public static get ROLE() {
        return {
            ACT: "act",
            ADMIN: "admin",
            HOST: "host"
        }
    }
 
    public static get SERVICE_VALUES(): string[] {
        return [
            "production-houses",
            "actors",
            "live-auditions",
            "closed-auditions",
            "shortlisted",
            "others"
        ];
    }
 
    public static get REGEX_VALIDATOR() {
        return {
            MOBILE_PATTERN: "^[0-9]{10}$",
            ALPHA_PATTERN: "^[a-zA-Z]+$",
            HEX_STRING: "^[0-9A-Fa-f]{24}$",
        }
    }
 }