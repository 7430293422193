import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-how-to-host-act',
  templateUrl: './how-to-host-act.component.html',
  styleUrls: ['./how-to-host-act.component.css']
})
export class HowToHostActComponent implements OnInit {

  constructor(private location: Location) { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  load(path):void {
    location.href = `${location.href.split('#/')[0]}#/${path}`
    location.reload()
  }

}
