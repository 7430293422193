import { Component, OnInit } from '@angular/core';
import { AioStoriesServices } from './services/aio-stories-services';
import { Location } from '@angular/common';

@Component({
  selector: 'app-aio-stories',
  templateUrl: './aio-stories.component.html',
  styleUrls: ['./aio-stories.component.css']
})
export class AioStoriesComponent implements OnInit {
  public blog;
  public id;
  constructor(public storiesService:AioStoriesServices, private location: Location) { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }
  getDetails(){
    this.storiesService.getBlogsDetails(this.id)
    .subscribe((response)=>{
        if(response.success){
           this.blog=response.data;
           this.storiesService.getMedia(this.blog.banner)
              .subscribe(
                (response) => {
                  if (response.success)
                  this.blog.imageSrc = response.file
                  // //console.log(item.imageSrc)
                },
                (error) => {
                  this.blog.imageSrc="assets/images/st01.jpg" 
                  //console.log("Image loading failed") 
                });

        }else{
         
        }
    },(error)=>{
      
    })
  }
  load(path):void {
    location.href = `${location.href.split('#/')[0]}#/${path}`
    location.reload()
  }

}
