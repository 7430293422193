import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import swal from 'sweetalert2';
import { Constants } from './../config/constant';
import { ContactService } from './services/contact.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})

export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  public services: string[] = Constants.SERVICE_VALUES
  constructor(
    private location: Location,
    public fb: FormBuilder,
    public contactService: ContactService
  ) {
    this.createForm()
  }

  ngOnInit() {
    window.scrollTo(0, 0);
  }
  createForm() {
    this.contactForm = this.fb.group({
      'email': ['', Validators.compose([Validators.required, Validators.email])],
      'mobile': ['', Validators.compose([Validators.required, Validators.pattern(Constants.REGEX_VALIDATOR.MOBILE_PATTERN)])],
      'name': ['', Validators.compose([Validators.required, Validators.minLength(4), Validators.pattern(Constants.REGEX_VALIDATOR.ALPHA_PATTERN)])],
      'subject': ['', Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(65)])],
      'service': ['', Validators.required],
      'message': ['', Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(512)])]
    });
  }
  get email() { return this.contactForm.get('email'); }
  get mobile() { return this.contactForm.get('mobile'); }
  get name() { return this.contactForm.get('name'); }
  get subject() { return this.contactForm.get('subject'); }
  get service() { return this.contactForm.get('service'); }
  get message() { return this.contactForm.get('message'); }

  send(values) {
    if (this.contactForm.invalid) {
      swal('Oops!!', 'Please submit with valid values', 'error')
    } else {
      // //console.log(values)
      // let dataToSend={
      //   email:values.email,
      //   mobile:values.mobile,
      //   name:values.name,
      //   message:values.message,
      //   subject:values.subject,
      //   service:values.service
      // }
      this.contactService.submitMessage(values)
        .subscribe((response: any) => {
          if (response.success) {
            this.contactForm.reset();
            swal({
              title: 'Success',
              text: response.message,
              type: 'success'
            })
          } else {
            swal({
              title: 'Error',
              text: response.message,
              type: 'error'
            })
          }
        }, (error: any) => {
          swal({
            title: 'Error',
            text: (error.error.message) ? error.error.message : 'Can\'t process this request right now!  Try after sometime.',
            type: 'error'
          })
        })
    }
  }
  load(path):void {
    location.href = `${location.href.split('#/')[0]}#/${path}`
    location.reload()
  }

}
