import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment as env } from '../../../environments/environment';


@Injectable()
export class ContactService {

    public baseUrl = `${env.baseURL}/api/website`;

    constructor(public http: HttpClient) { }

    errorHandler(error: Response) {
        return Observable.throw(error.json() || "Server error");
      }
    
      submitMessage(body): Observable<any> {
        return this.http.post(`${this.baseUrl}/contact-us`, body);
      }
      
}