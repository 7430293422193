import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

  constructor(private location: Location) { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  load(path):void {
    location.href = `${location.href.split('#/')[0]}#/${path}`
    location.reload()
  }

}
