import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-why-aio',
  templateUrl: './why-aio.component.html',
  styleUrls: ['./why-aio.component.css']
})
export class WhyAioComponent implements OnInit {

  constructor(private location: Location) { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  load(path):void {
    location.href = `${location.href.split('#/')[0]}#/${path}`
    location.reload()
  }


}
