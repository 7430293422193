import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-ek-garam-chai',
  templateUrl: './ek-garam-chai.component.html',
  styleUrls: ['./ek-garam-chai.component.css']
})
export class EkGaramChaiComponent implements OnInit {

  constructor(private location: Location) { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }
  load(path):void {
    location.href = `${location.href.split('#/')[0]}#/${path}`
    location.reload()
  }

}
