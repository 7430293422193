import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import swal from 'sweetalert2';
import { FooterService } from './services/footer-service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
   
    subscribeForm: FormGroup;

    constructor( public fb: FormBuilder,
        public layoutService: FooterService) {
            this.subscribeForm = new FormGroup({
                email: new FormControl('', Validators.compose([Validators.required, CustomValidators.email])),
              });
         }

    ngOnInit() {
    }

    get email() { return this.subscribeForm.get('email'); }

    onSubscribe($event,values){
        if(this.email.invalid){
           if(this.email.errors.required){
            swal({
                type: 'error',
                title: "Oops..!",
                text:'E-mail is required',
            });
           }
           if(this.email.errors.email){
            swal({
                type: 'error',
                title: "Oops..!",
                text: 'Invalid e-mail address.',
            });
           }
        }else{
           this.layoutService.subscribeNewsletter({email:this.email.value})
           .subscribe((response: any) => {
            if (response.success) {
              swal('Success', 'You\'ve successfully subscribed to our newsletters', 'success')
            } else {
                swal('Error', response.message, 'error')
            }
          }, (error) => {
              //console.log('working 2')
              if(error.error.message == 'You are already subscribed to our mailing list'){
                swal('Oops.. !', error.error.message, 'warning')
              }else{
                swal('Error', (error.error.message) ? error.error.message : 'Can\'t process this request right now!  Try after sometime.', 'error') 
              }
          })
        }
    }


}
