import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment as env } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable()
export class AioStoriesServices {

    public baseUrl = `${env.baseURL}/api/blogs`;

    constructor(public http: HttpClient) { }

    errorHandler(error: Response) {
        return Observable.throw(error.json() || "Server error");
    }
    getBlogsList(currentPage): Observable<any> {
    const queryParams = new HttpParams().set('page', currentPage);
    return this.http.get(`${this.baseUrl}/website`, { params: queryParams });
    }
    getBlogsDetails(id): Observable<any> {
    const queryParams = new HttpParams().set('id', id);
    return this.http.get(`${this.baseUrl}/details`, { params: queryParams });
    }
    getMedia(id): Observable<any> {
    const queryParams = new HttpParams().set('id', id);
    return this.http.get(`${this.baseUrl}/media`, { params: queryParams });
    }

      
}