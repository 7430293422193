import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment as env } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable()
export class HomeService {

    public baseUrl = `${env.baseURL}/api`;
    public reloadHome:boolean=false;
        
    constructor(public http: HttpClient) { }

    errorHandler(error: Response) {
        return Observable.throw(error.json() || "Server error");
      }
      getCounts(): Observable<any>{
        sessionStorage.setItem('reloadFaq', 'true')
        sessionStorage.setItem('reloadAbout', 'true')
        return this.http.get(`${this.baseUrl}/website/counts`);

      }
      
      getBlogs(page,limit): Observable<any>{
       let queryParams=new HttpParams().set('limit',limit)
        queryParams=queryParams.set('page',page)
        return this.http.get(`${this.baseUrl}/blogs/website`,{ params: queryParams })
      }
      getBlogsDetails(id): Observable<any> {
        const queryParams = new HttpParams().set('id', id);
        return this.http.get(`${this.baseUrl}/details`, { params: queryParams });
      }
      getMedia(id): Observable<any> {
        const queryParams = new HttpParams().set('id', id);
        return this.http.get(`${this.baseUrl}/media`, { params: queryParams });
      }
      getCountry(): Observable<any>{
        return this.http.get(`https://ipinfo.io/json?`);
        
      }
}