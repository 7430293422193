import { Component, OnInit } from '@angular/core';
import { HomeService } from './services/home-service';
import { TestimonialService } from '../testimonials/services/testimonial-service'
import { Location } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  public hostCount:number=0;
  public actCount:number=0;
  public liveAuditionCount:number=0;
  public closedAuditionCount:number=0;
  public shortlistedCount:number=0;
  public testimonials:any;
  
  
  
  public currentPage:number=1;
  public limit:number=2;
  public stories:any;


  constructor(public homeService:HomeService, public testimonialService:TestimonialService, private location: Location) {
    this.getCounts()
    this.getBlogs()
    this.fetchTestimonials();
  }

  ngOnInit() {
  
  }
  load(path):void {
    location.href = `${location.href.split('#/')[0]}#/${path}`
    location.reload()
  }
  

  fetchTestimonials(){
    this.testimonials = this.testimonialService.getTestimonial();
  }
  getCounts(){
    this.homeService.getCounts()
    .subscribe((response:any)=>{
      if(response.success){
         //console.log(response)
         this.hostCount=response.data.host;
         this.actCount=response.data.act;
         this.liveAuditionCount=response.data.liveAudition;
         this.closedAuditionCount=response.data.closedAudition;
         this.shortlistedCount=response.data.shortlisted + 270;
      }
    },
    (error)=>{

    })
  }

  getBlogs(){
    this.homeService.getBlogs(this.currentPage,this.limit)
    .subscribe((response:any)=>{
      //console.log(response)
      if(response.success){
        this.stories=response.data.blogs;
        
        //this.storieFetched=true;
        // this.stories.forEach((item) => {
        //   this.homeService.getMedia(item.image)
        //     .subscribe((response) => {
        //       if (response.success)
        //         item.imageSrc = response.file
        //       // //console.log(item.imageSrc)
        //     },
        //     (error) => {
        //       item.imageSrc="assets/images/st01.jpg" 
        //       //console.log("Image loading failed") 
        //     });
        // });
      }
    },
    (error)=>{

    })
  }

}
