import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  constructor(private location: Location) { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }
  load(path):void {
    location.href = `${location.href.split('#/')[0]}#/${path}`
    location.reload()
  }
  
}
